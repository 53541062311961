"use strict";

(function($) {

	var labelID;
	$(".wpcf7 input, .wpcf7 select, .wpcf7 textarea").focus(function() {
		$(this).parent().siblings("label").addClass("wpcf7-field-label--active");
	});

	$(".wpcf7 input, .wpcf7 select, .wpcf7 textarea").focusout(function() {
		if($(this).val().length === 0) {
			$(this).parent().siblings("label").removeClass("wpcf7-field-label--active");
		}
	});

	$(".wpcf7 label").click(function() {
		labelID = $(this).attr("for");
		$("#" + labelID).trigger("click");
	});

	$(".wpcf7 input, .wpcf7 select, .wpcf7 textarea").each(function() {
		if($(this).val().length !== 0) {
			$(this).parent().siblings("label").addClass("wpcf7-field-label--active");
		} else {
			$(this).parent().siblings("label").removeClass("wpcf7-field-label--active");
		}
	});

	// Handle menu resize.
	$(window).on("resize load", function(e) {
		if(e.type === "resize") {
			let headerHeight = $(".site__header").height();
			$("body").css("padding-top", headerHeight);
		}

		if($(window).width() > 992) {
			if($(".fl-menu-mobile-toggle").hasClass("fl-menu-mobile-toggle--active")) {
				$(".fl-menu-mobile-toggle").removeClass("fl-menu-mobile-toggle--active");
			}
		}
	});
})(jQuery);

// // Form: submit.
// document.addEventListener("wpcf7submit", (e) => {
// 	let form = e.target,
// 		allFields = form.querySelectorAll(".wpcf7-field-label"),
// 		invalidFields = form.querySelectorAll(".wpcf7-not-valid"),
// 		formSubmitButon = form.querySelector(".wpcf7-submit");

// 	for(let i = 0; i < allFields.length; i++) {
// 		allFields[i].classList.remove("wpcf7-field-label--invalid");
// 	}

// 	for(let ii = 0; ii < invalidFields.length; ii++) {
// 		invalidFields[ii].parentNode.parentNode.querySelector(".wpcf7-field-label").classList.add("wpcf7-field-label--invalid");
// 	}

// 	if(invalidFields.length === 0) {
// 		form.querySelector(".reminder").classList.add("reminder--hidden");
// 		form.querySelector(".success").classList.remove("success--hidden");

// 		formSubmitButon.classList.add("wpcf7-submit--success");
// 		formSubmitButon.value = "Sent";

// 		setTimeout(function() {
// 			formSubmitButon.classList.remove("wpcf7-submit--success");
// 			formSubmitButon.value = "Submit";
// 		}, 2000);
// 	} else {
// 		form.querySelector(".success").classList.add("success--hidden");
// 		form.querySelector(".reminder").classList.remove("reminder--hidden");
// 	}
// });
